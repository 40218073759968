import './style.css';

const Footer = () => {
  return (
    <footer className="general_footer">
     
    </footer>
  )
}

export default Footer;
